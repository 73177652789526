import { Component, OnInit } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import {
  FormBuilder,
  FormGroup,
  Validators,
  AbstractControl,
  ValidationErrors,
} from "@angular/forms";
import { AuthenticationService } from "../../core/services/auth.service";
import { AppConfigService } from "../../app-config.service";
import { TranslateService } from "@ngx-translate/core";

@Component({
    selector: "app-change-password-dialog",
    templateUrl: "./change-password-dialog.component.html",
    styleUrls: ["./change-password-dialog.component.scss"],
    standalone: false
})
export class ChangePasswordDialogComponent implements OnInit {
  passwordForm: FormGroup;
  passwordErrors: string[] = [];

  constructor(
    public dialogRef: MatDialogRef<ChangePasswordDialogComponent>,
    private formBuilder: FormBuilder,
    private authService: AuthenticationService,
    public config: AppConfigService,
    private translate: TranslateService
  ) {
    this.passwordForm = this.formBuilder.group(
      {
        currentPassword: ["", Validators.required],
        newPassword: [
          "",
          [
            Validators.required,
            Validators.minLength(8),
            this.passwordStrengthValidator,
          ],
        ],
        confirmPassword: ["", Validators.required],
      },
      { validator: this.passwordMatchValidator }
    );
  }

  ngOnInit() {
    this.passwordForm.get("newPassword").valueChanges.subscribe(() => {
      this.updatePasswordErrors();
    });
  }

  passwordMatchValidator(form: FormGroup): ValidationErrors | null {
    const newPassword = form.get("newPassword").value;
    const confirmPassword = form.get("confirmPassword").value;
    return newPassword === confirmPassword ? null : { mismatch: true };
  }

  passwordStrengthValidator(control: AbstractControl): ValidationErrors | null {
    const value = control.value;
    const errors: ValidationErrors = {};

    if (!/(?=.*[a-z])/.test(value)) {
      errors["lowercase"] = true;
    }
    if (!/(?=.*[A-Z])/.test(value)) {
      errors["uppercase"] = true;
    }
    if (!/(?=.*\d)/.test(value)) {
      errors["digit"] = true;
    }
    if (!/(?=.*[!@#$%^&*])/.test(value)) {
      errors["specialChar"] = true;
    }
    if (value.length < 8) {
      errors["minlength"] = true;
    }

    return Object.keys(errors).length ? errors : null;
  }

  updatePasswordErrors() {
    this.passwordErrors = [];
    const errors = this.passwordForm.get("newPassword").errors;
    if (errors) {
      if (errors["minlength"]) {
        this.passwordErrors.push(
          this.translate.instant("CHANGE_PASSWORD.ERROR_MIN_LENGTH")
        );
      }
      if (errors["lowercase"]) {
        this.passwordErrors.push(
          this.translate.instant("CHANGE_PASSWORD.ERROR_LOWERCASE")
        );
      }
      if (errors["uppercase"]) {
        this.passwordErrors.push(
          this.translate.instant("CHANGE_PASSWORD.ERROR_UPPERCASE")
        );
      }
      if (errors["digit"]) {
        this.passwordErrors.push(
          this.translate.instant("CHANGE_PASSWORD.ERROR_DIGIT")
        );
      }
      if (errors["specialChar"]) {
        this.passwordErrors.push(
          this.translate.instant("CHANGE_PASSWORD.ERROR_SPECIAL_CHAR")
        );
      }
    }
  }

  async onSubmit() {
    if (this.passwordForm.valid) {
      try {
        await this.authService.changePassword(
          this.passwordForm.get("currentPassword").value,
          this.passwordForm.get("newPassword").value
        );
        // Handle successful password change
        this.dialogRef.close(true);
      } catch (error) {
        // Handle error
        console.error("Password change failed:", error);
      }
    }
  }

  onCancel() {
    this.dialogRef.close(false);
  }
}
