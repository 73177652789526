import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { PlotlyComponent, PlotlyService } from "angular-plotly.js";

declare var Plotly: any;
@Component({
    selector: "app-statistics-pos-neg",
    templateUrl: "./statistics-pos-neg.component.html",
    styleUrls: ["./statistics-pos-neg.component.scss"],
    standalone: false
})
export class StatisticsPosNegComponent implements OnInit, OnChanges {
  @ViewChild("posneg") posneg: PlotlyComponent;
  @Input("labels") labels: string[] = []; // = ["M", "T", "W", "T", "F", "S", "S"];
  @Input("positive") positive = []; // [5, 2, 6, 1, 2, 8, 6];
  @Input("negative") negative = []; // [-5, -2, -3, -8, -1, 0, -1];
  @Output("plotEl") plotEl = new EventEmitter<any>();
  didInit = false;
  graph = {
    config: {
      displayModeBar: false,
      responsive: true,
      clickmode: "event+select",
      scrollZoom: false,
    },
    data: [
      {
        name: "Positive",
        orientation: "v",
        text: "",
        type: "bar",
        y: this.positive,
      },
      {
        name: "Negative",
        orientation: "v",
        text: "",
        type: "bar",
        y: this.negative,
      },
    ],
    layout: {
      barmode: "overlay",
      title: "",
      margin: {
        l: 24,
        r: 24,
        b: 6,
        t: 6,
        pad: 12,
      },
      showlegend: false,
      autosize: true,
      height: 200,
      hovermode: false,
      clickmode: false,
      dragmode: false,
      paper_bgcolor: "transparent",
      plot_bgcolor: "transparent",
      font: {
        size: "12",
        color: "black",
      },
      legend: {
        itemclick: false,
      },
      xaxis: {
        tickmode: "array",
        automargin: true,
      },
      yaxis: {
        showgrid: false,
        showticklabels: false,
        // domain: [0, 0],
        automargin: true,
      },
      colorway: ["#8BA19D", "#fab687"],
    },
  };

  constructor(public plotlyService: PlotlyService) {}

  ngOnInit() {}
  afterInit() {
    setTimeout(() => {
      this.didInit = true;
      this.updateGraph();
    }, 5);
    setTimeout(() => {
      this.didInit = true;
      this.updateGraph();
      this.plotEl.emit(this.posneg.plotlyInstance);
    }, 1000);
    setTimeout(() => {
      this.didInit = true;
      this.updateGraph();
    }, 5000);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.didInit) {
      this.updateGraph();
    }
  }

  updateGraph() {
    const neg = this.negative.map((val) => -val);
    this.graph.data = [
      {
        name: "Positive",
        orientation: "v",
        text: "",
        type: "bar",
        y: this.positive,
      },
      {
        name: "Negative",
        orientation: "v",
        text: "",
        type: "bar",
        y: neg,
      },
    ];
    this.graph.layout.xaxis["ticktext"] = this.labels;
    this.graph.layout.xaxis["tickvals"] = Object.keys(this.labels).map(
      (val) => val
    );
    if (this.posneg) {
      this.posneg.updatePlot();
    }
  }

  getData() {}
}
