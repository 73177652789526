import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { AuthenticationService } from "../../core/services/auth.service";
import { EncryptionService } from "../../encryption.service";

@Component({
    selector: "app-chat-message",
    templateUrl: "./chat-message.component.html",
    styleUrls: ["./chat-message.component.scss"],
    standalone: false
})
export class ChatMessageComponent implements OnInit {
  e2e = false;
  currentUser: any;
  align = "none";
  @Input() isNotification = false;
  @Input() userId: any = false;
  @Input() index: any = 0;
  @Input() message: any;
  @Output() showData = new EventEmitter<any>();

  confidantChatTypeTranslation = {
    anonymous_urgent: "NEW_REPORT.TYPE_ANONYMOUS_URGENT",
    anonymous_not_urgent: "NEW_REPORT.TYPE_ANONYMOUS_NOT_URGENT",
  };

  constructor(
    public authService: AuthenticationService,
    private encryptionService: EncryptionService
  ) {}

  ngOnInit() {
    if (this.authService.dashboardType === "webchat") {
      if (this.userId) {
        this.align = "start";
      } else {
        this.align = "end";
      }
    } else {
      if (this.userId) {
        this.align = "end";
      } else {
        this.align = "start";
      }
    }
    this.currentUser = this.authService.getCurrentUser();

    if (this.message.message.startsWith("-----BEGIN PGP MESSAGE-----")) {
      this.e2e = true;
      this.decryptMessage();
    }
  }

  async decryptMessage() {
    try {
      this.message.message = await this.encryptionService.decryptMessage(
        this.message.message
      );
      this.e2e = true;
    } catch (e) {
      this.e2e = false;
      console.log("Cannot decrypt", e);
    }
  }

  openData() {
    this.showData.emit(this.message);
  }
}
