import * as i0 from '@angular/core';
import { Injectable, EventEmitter, Component, ViewChild, Input, Output, NgModule } from '@angular/core';
import * as i3 from '@angular/common';
import { CommonModule } from '@angular/common';
class PlotlyService {
  static {
    this.instances = [];
  }
  static {
    this.plotly = undefined;
  }
  static {
    this.moduleName = undefined;
  }
  static setModuleName(moduleName) {
    PlotlyService.moduleName = moduleName;
  }
  static getModuleName() {
    return PlotlyService.moduleName;
  }
  static setPlotly(plotly) {
    if (typeof plotly === 'object' && typeof plotly.react !== 'function') {
      throw new Error('Invalid plotly.js version. Please, use any version above 1.40.0');
    }
    PlotlyService.plotly = plotly;
  }
  static insert(instance) {
    const index = PlotlyService.instances.indexOf(instance);
    if (index === -1) {
      PlotlyService.instances.push(instance);
    }
    return instance;
  }
  static remove(div) {
    const index = PlotlyService.instances.indexOf(div);
    if (index >= 0) {
      PlotlyService.instances.splice(index, 1);
      PlotlyService.plotly.purge(div);
    }
  }
  getInstanceByDivId(id) {
    for (const instance of PlotlyService.instances) {
      if (instance && instance.id === id) {
        return instance;
      }
    }
    return undefined;
  }
  async getPlotly() {
    await this.waitFor(() => this._getPlotly() !== 'waiting');
    return this._getPlotly();
  }
  _getPlotly() {
    if (typeof PlotlyService.plotly === 'undefined') {
      const msg = PlotlyService.moduleName === 'ViaCDN' ? `Error loading Peer dependency plotly.js from CDN url` : `Peer dependency plotly.js isn't installed`;
      throw new Error(msg);
    }
    return PlotlyService.plotly;
  }
  waitFor(fn) {
    return new Promise(resolve => {
      const localFn = () => {
        fn() ? resolve() : setTimeout(localFn, 10);
      };
      localFn();
    });
  }
  async newPlot(div, data, layout, config, frames) {
    await this.waitFor(() => this._getPlotly() !== 'waiting');
    if (frames) {
      const obj = {
        data,
        layout,
        config,
        frames
      };
      return this._getPlotly().newPlot(div, obj).then(() => PlotlyService.insert(div));
    }
    return this._getPlotly().newPlot(div, data, layout, config).then(() => PlotlyService.insert(div));
  }
  plot(div, data, layout, config, frames) {
    if (frames) {
      const obj = {
        data,
        layout,
        config,
        frames
      };
      return this._getPlotly().newPlot(div, obj);
    }
    return this._getPlotly().newPlot(div, data, layout, config);
  }
  update(div, data, layout, config, frames) {
    if (frames) {
      const obj = {
        data,
        layout,
        config,
        frames
      };
      return this._getPlotly().react(div, obj);
    }
    return this._getPlotly().react(div, data, layout, config);
  }
  resize(div) {
    return this._getPlotly().Plots.resize(div);
  }
  static {
    this.ɵfac = i0.ɵɵngDeclareFactory({
      minVersion: "12.0.0",
      version: "16.2.12",
      ngImport: i0,
      type: PlotlyService,
      deps: [],
      target: i0.ɵɵFactoryTarget.Injectable
    });
  }
  static {
    this.ɵprov = i0.ɵɵngDeclareInjectable({
      minVersion: "12.0.0",
      version: "16.2.12",
      ngImport: i0,
      type: PlotlyService,
      providedIn: 'root'
    });
  }
}
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "16.2.12",
  ngImport: i0,
  type: PlotlyService,
  decorators: [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }]
});
class PlotlyThemeLoaderService {
  constructor() {
    this._isLoading = false;
  }
  get isLoading() {
    return this._isLoading;
  }
  load(themeName) {
    this._isLoading = true;
    return new Promise(resolve => {
      // import(`./themes/${themeName}.json`).then(data => {
      resolve(true);
      //     this._isLoading = false;
      // });
    });
  }
  static {
    this.ɵfac = i0.ɵɵngDeclareFactory({
      minVersion: "12.0.0",
      version: "16.2.12",
      ngImport: i0,
      type: PlotlyThemeLoaderService,
      deps: [],
      target: i0.ɵɵFactoryTarget.Injectable
    });
  }
  static {
    this.ɵprov = i0.ɵɵngDeclareInjectable({
      minVersion: "12.0.0",
      version: "16.2.12",
      ngImport: i0,
      type: PlotlyThemeLoaderService,
      providedIn: 'root'
    });
  }
}
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "16.2.12",
  ngImport: i0,
  type: PlotlyThemeLoaderService,
  decorators: [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }]
});

/* eslint-disable @angular-eslint/no-conflicting-lifecycle */
// @dynamic
class PlotlyComponent {
  constructor(plotly, themeLoader, iterableDiffers, keyValueDiffers) {
    this.plotly = plotly;
    this.themeLoader = themeLoader;
    this.iterableDiffers = iterableDiffers;
    this.keyValueDiffers = keyValueDiffers;
    this.defaultClassName = 'js-plotly-plot';
    this.theme = "none";
    this.revision = 0;
    this.debug = false;
    this.useResizeHandler = false;
    this.updateOnLayoutChange = true;
    this.updateOnDataChange = true;
    this.updateOnlyWithRevision = false;
    this.initialized = new EventEmitter();
    this.update = new EventEmitter();
    this.purge = new EventEmitter();
    // eslint-disable-next-line @angular-eslint/no-output-native
    this.error = new EventEmitter();
    this.afterExport = new EventEmitter();
    this.afterPlot = new EventEmitter();
    this.animated = new EventEmitter();
    this.animatingFrame = new EventEmitter();
    this.animationInterrupted = new EventEmitter();
    this.autoSize = new EventEmitter();
    this.beforeExport = new EventEmitter();
    this.beforeHover = new EventEmitter();
    this.buttonClicked = new EventEmitter();
    /**
     * @deprecated DEPRECATED: Reconsider using `(plotlyClick)` instead of `(click)` to avoid event conflict. Please check https://github.com/plotly/angular-plotly.js#FAQ
     */
    // eslint-disable-next-line @angular-eslint/no-output-native
    this.click = new EventEmitter();
    this.plotlyClick = new EventEmitter();
    this.clickAnnotation = new EventEmitter();
    this.deselect = new EventEmitter();
    this.doubleClick = new EventEmitter();
    this.framework = new EventEmitter();
    this.hover = new EventEmitter();
    this.legendClick = new EventEmitter();
    this.legendDoubleClick = new EventEmitter();
    /**
     * @deprecated DEPRECATED: Event react is not list as an plotly.js event
     */
    this.react = new EventEmitter();
    this.relayout = new EventEmitter();
    this.relayouting = new EventEmitter();
    this.restyle = new EventEmitter();
    this.redraw = new EventEmitter();
    this.selected = new EventEmitter();
    this.selecting = new EventEmitter();
    this.sliderChange = new EventEmitter();
    this.sliderEnd = new EventEmitter();
    this.sliderStart = new EventEmitter();
    this.sunburstclick = new EventEmitter();
    this.transitioning = new EventEmitter();
    this.transitionInterrupted = new EventEmitter();
    this.unhover = new EventEmitter();
    /**
     * @deprecated DEPRECATED: Event treemapclick is not list as an plotly.js event
     */
    this.treemapclick = new EventEmitter();
    this.webglcontextlost = new EventEmitter();
    this.eventNames = ['afterExport', 'afterPlot', 'animated', 'animatingFrame', 'animationInterrupted', 'autoSize', 'beforeExport', 'beforeHover', 'buttonClicked', 'clickAnnotation', 'deselect', 'doubleClick', 'framework', 'hover', 'legendClick', 'legendDoubleClick', 'react', 'relayout', 'relayouting', 'restyle', 'redraw', 'selected', 'selecting', 'sliderChange', 'sliderEnd', 'sliderStart', 'sunburstclick', 'transitioning', 'transitionInterrupted', 'unhover', 'treemapclick', 'webglcontextlost'];
  }
  ngOnInit() {
    this.createPlot().then(() => {
      const figure = this.createFigure();
      this.initialized.emit(figure);
    });
    if (this.click.observers.length > 0) {
      const msg = 'DEPRECATED: Reconsider using `(plotlyClick)` instead of `(click)` to avoid event conflict. ' + 'Please check https://github.com/plotly/angular-plotly.js#FAQ';
      console.error(msg);
    }
    // if (this.theme != 'none') this.loadTheme();
  }
  ngOnDestroy() {
    if (typeof this.resizeHandler === 'function') {
      this.getWindow().removeEventListener('resize', this.resizeHandler);
      this.resizeHandler = undefined;
    }
    if (this.plotlyInstance) {
      const figure = this.createFigure();
      this.purge.emit(figure);
      PlotlyService.remove(this.plotlyInstance);
    }
  }
  ngOnChanges(changes) {
    let shouldUpdate = false;
    const revision = changes['revision'];
    if (revision && !revision.isFirstChange()) {
      shouldUpdate = true;
    }
    const debug = changes['debug'];
    if (debug && !debug.isFirstChange()) {
      shouldUpdate = true;
    }
    if (shouldUpdate) {
      this.updatePlot();
    }
    this.updateWindowResizeHandler();
  }
  ngDoCheck() {
    if (this.updateOnlyWithRevision) {
      return false;
    }
    let shouldUpdate = false;
    if (this.updateOnLayoutChange) {
      if (this.layoutDiffer) {
        const layoutHasDiff = this.layoutDiffer.diff(this.layout);
        if (layoutHasDiff) {
          shouldUpdate = true;
        }
      } else if (this.layout) {
        this.layoutDiffer = this.keyValueDiffers.find(this.layout).create();
      } else {
        this.layoutDiffer = undefined;
      }
    }
    if (this.updateOnDataChange) {
      if (this.dataDiffer) {
        const dataHasDiff = this.dataDiffer.diff(this.data);
        if (dataHasDiff) {
          shouldUpdate = true;
        }
      } else if (Array.isArray(this.data)) {
        this.dataDiffer = this.iterableDiffers.find(this.data).create(this.dataDifferTrackBy);
      } else {
        this.dataDiffer = undefined;
      }
    }
    if (shouldUpdate && this.plotlyInstance) {
      this.updatePlot();
    }
  }
  getData() {
    return this.data ?? [];
  }
  getWindow() {
    return window;
  }
  getClassName() {
    let classes = [this.defaultClassName];
    if (Array.isArray(this.className)) {
      classes = classes.concat(this.className);
    } else if (this.className) {
      classes.push(this.className);
    }
    return classes.join(' ');
  }
  createPlot() {
    return this.plotly.newPlot(this.plotEl.nativeElement, this.getData(), this.layout, this.config, this.frames).then(plotlyInstance => {
      this.plotlyInstance = plotlyInstance;
      this.getWindow().gd = this.debug ? plotlyInstance : undefined;
      this.eventNames.forEach(name => {
        const eventName = `plotly_${name.toLowerCase()}`;
        const event = this[name];
        plotlyInstance.on(eventName, data => event.emit(data));
      });
      plotlyInstance.on('plotly_click', data => {
        this.plotlyClick.emit(data);
      });
      this.updateWindowResizeHandler();
    }, err => {
      console.error('Error while plotting:', err);
      this.error.emit(err);
    });
  }
  createFigure() {
    const p = this.plotlyInstance;
    const figure = {
      data: p.data,
      layout: p.layout,
      frames: p._transitionData ? p._transitionData._frames : null
    };
    return figure;
  }
  updatePlot() {
    if (!this.plotlyInstance) {
      const error = new Error(`Plotly component wasn't initialized`);
      this.error.emit(error);
      throw error;
    }
    const layout = {
      ...this.layout
    };
    return this.plotly.update(this.plotlyInstance, this.getData(), layout, this.config, this.frames).then(() => {
      const figure = this.createFigure();
      this.update.emit(figure);
    }, err => {
      console.error('Error while updating plot:', err);
      this.error.emit(err);
    });
  }
  updateWindowResizeHandler() {
    if (this.useResizeHandler) {
      if (this.resizeHandler === undefined) {
        this.resizeHandler = () => this.plotly.resize(this.plotlyInstance);
        this.getWindow().addEventListener('resize', this.resizeHandler);
      }
    } else {
      if (typeof this.resizeHandler === 'function') {
        this.getWindow().removeEventListener('resize', this.resizeHandler);
        this.resizeHandler = undefined;
      }
    }
  }
  dataDifferTrackBy(_, item) {
    const obj = Object.assign({}, item, {
      uid: ''
    });
    return JSON.stringify(obj);
  }
  loadTheme() {
    if (this.layout !== undefined) {
      const msg = 'You fulfill both `theme` and `layout` properties. This will overwrite the `layout` data with the `theme` data.';
      console.warn(msg);
    }
    this.themeLoader.load(this.theme).then(theme => this.layout = theme);
  }
  static {
    this.ɵfac = i0.ɵɵngDeclareFactory({
      minVersion: "12.0.0",
      version: "16.2.12",
      ngImport: i0,
      type: PlotlyComponent,
      deps: [{
        token: PlotlyService
      }, {
        token: PlotlyThemeLoaderService
      }, {
        token: i0.IterableDiffers
      }, {
        token: i0.KeyValueDiffers
      }],
      target: i0.ɵɵFactoryTarget.Component
    });
  }
  static {
    this.ɵcmp = i0.ɵɵngDeclareComponent({
      minVersion: "14.0.0",
      version: "16.2.12",
      type: PlotlyComponent,
      selector: "plotly-plot",
      inputs: {
        data: "data",
        layout: "layout",
        config: "config",
        frames: "frames",
        style: "style",
        theme: "theme",
        divId: "divId",
        revision: "revision",
        className: "className",
        debug: "debug",
        useResizeHandler: "useResizeHandler",
        updateOnLayoutChange: "updateOnLayoutChange",
        updateOnDataChange: "updateOnDataChange",
        updateOnlyWithRevision: "updateOnlyWithRevision"
      },
      outputs: {
        initialized: "initialized",
        update: "update",
        purge: "purge",
        error: "error",
        afterExport: "afterExport",
        afterPlot: "afterPlot",
        animated: "animated",
        animatingFrame: "animatingFrame",
        animationInterrupted: "animationInterrupted",
        autoSize: "autoSize",
        beforeExport: "beforeExport",
        beforeHover: "beforeHover",
        buttonClicked: "buttonClicked",
        click: "click",
        plotlyClick: "plotlyClick",
        clickAnnotation: "clickAnnotation",
        deselect: "deselect",
        doubleClick: "doubleClick",
        framework: "framework",
        hover: "hover",
        legendClick: "legendClick",
        legendDoubleClick: "legendDoubleClick",
        react: "react",
        relayout: "relayout",
        relayouting: "relayouting",
        restyle: "restyle",
        redraw: "redraw",
        selected: "selected",
        selecting: "selecting",
        sliderChange: "sliderChange",
        sliderEnd: "sliderEnd",
        sliderStart: "sliderStart",
        sunburstclick: "sunburstclick",
        transitioning: "transitioning",
        transitionInterrupted: "transitionInterrupted",
        unhover: "unhover",
        treemapclick: "treemapclick",
        webglcontextlost: "webglcontextlost"
      },
      providers: [PlotlyService],
      viewQueries: [{
        propertyName: "plotEl",
        first: true,
        predicate: ["plot"],
        descendants: true,
        static: true
      }],
      usesOnChanges: true,
      ngImport: i0,
      template: `<div #plot [attr.id]="divId" [ngClass]="getClassName()" [ngStyle]="style">
      <ng-content></ng-content>
    </div>`,
      isInline: true,
      dependencies: [{
        kind: "directive",
        type: i3.NgClass,
        selector: "[ngClass]",
        inputs: ["class", "ngClass"]
      }, {
        kind: "directive",
        type: i3.NgStyle,
        selector: "[ngStyle]",
        inputs: ["ngStyle"]
      }]
    });
  }
}
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "16.2.12",
  ngImport: i0,
  type: PlotlyComponent,
  decorators: [{
    type: Component,
    args: [{
      selector: 'plotly-plot',
      template: `<div #plot [attr.id]="divId" [ngClass]="getClassName()" [ngStyle]="style">
      <ng-content></ng-content>
    </div>`,
      providers: [PlotlyService]
    }]
  }],
  ctorParameters: function () {
    return [{
      type: PlotlyService
    }, {
      type: PlotlyThemeLoaderService
    }, {
      type: i0.IterableDiffers
    }, {
      type: i0.KeyValueDiffers
    }];
  },
  propDecorators: {
    plotEl: [{
      type: ViewChild,
      args: ['plot', {
        static: true
      }]
    }],
    data: [{
      type: Input
    }],
    layout: [{
      type: Input
    }],
    config: [{
      type: Input
    }],
    frames: [{
      type: Input
    }],
    style: [{
      type: Input
    }],
    theme: [{
      type: Input
    }],
    divId: [{
      type: Input
    }],
    revision: [{
      type: Input
    }],
    className: [{
      type: Input
    }],
    debug: [{
      type: Input
    }],
    useResizeHandler: [{
      type: Input
    }],
    updateOnLayoutChange: [{
      type: Input
    }],
    updateOnDataChange: [{
      type: Input
    }],
    updateOnlyWithRevision: [{
      type: Input
    }],
    initialized: [{
      type: Output
    }],
    update: [{
      type: Output
    }],
    purge: [{
      type: Output
    }],
    error: [{
      type: Output
    }],
    afterExport: [{
      type: Output
    }],
    afterPlot: [{
      type: Output
    }],
    animated: [{
      type: Output
    }],
    animatingFrame: [{
      type: Output
    }],
    animationInterrupted: [{
      type: Output
    }],
    autoSize: [{
      type: Output
    }],
    beforeExport: [{
      type: Output
    }],
    beforeHover: [{
      type: Output
    }],
    buttonClicked: [{
      type: Output
    }],
    click: [{
      type: Output
    }],
    plotlyClick: [{
      type: Output
    }],
    clickAnnotation: [{
      type: Output
    }],
    deselect: [{
      type: Output
    }],
    doubleClick: [{
      type: Output
    }],
    framework: [{
      type: Output
    }],
    hover: [{
      type: Output
    }],
    legendClick: [{
      type: Output
    }],
    legendDoubleClick: [{
      type: Output
    }],
    react: [{
      type: Output
    }],
    relayout: [{
      type: Output
    }],
    relayouting: [{
      type: Output
    }],
    restyle: [{
      type: Output
    }],
    redraw: [{
      type: Output
    }],
    selected: [{
      type: Output
    }],
    selecting: [{
      type: Output
    }],
    sliderChange: [{
      type: Output
    }],
    sliderEnd: [{
      type: Output
    }],
    sliderStart: [{
      type: Output
    }],
    sunburstclick: [{
      type: Output
    }],
    transitioning: [{
      type: Output
    }],
    transitionInterrupted: [{
      type: Output
    }],
    unhover: [{
      type: Output
    }],
    treemapclick: [{
      type: Output
    }],
    webglcontextlost: [{
      type: Output
    }]
  }
});
class PlotlySharedModule {
  constructor() {}
  static {
    this.ɵfac = i0.ɵɵngDeclareFactory({
      minVersion: "12.0.0",
      version: "16.2.12",
      ngImport: i0,
      type: PlotlySharedModule,
      deps: [],
      target: i0.ɵɵFactoryTarget.NgModule
    });
  }
  static {
    this.ɵmod = i0.ɵɵngDeclareNgModule({
      minVersion: "14.0.0",
      version: "16.2.12",
      ngImport: i0,
      type: PlotlySharedModule,
      declarations: [PlotlyComponent],
      imports: [CommonModule],
      exports: [PlotlyComponent]
    });
  }
  static {
    this.ɵinj = i0.ɵɵngDeclareInjector({
      minVersion: "12.0.0",
      version: "16.2.12",
      ngImport: i0,
      type: PlotlySharedModule,
      providers: [PlotlyService],
      imports: [CommonModule]
    });
  }
}
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "16.2.12",
  ngImport: i0,
  type: PlotlySharedModule,
  decorators: [{
    type: NgModule,
    args: [{
      declarations: [PlotlyComponent],
      imports: [CommonModule],
      providers: [PlotlyService],
      exports: [PlotlyComponent]
    }]
  }],
  ctorParameters: function () {
    return [];
  }
});
class PlotlyModule {
  static {
    this.plotlyjs = {};
  }
  constructor() {
    if (!this.isValid()) {
      const msg = 'Invalid PlotlyJS object. Please check https://github.com/plotly/angular-plotly.js#quick-start' + ' to see how to add PlotlyJS to your project.';
      throw new Error(msg);
    }
    PlotlyService.setPlotly(PlotlyModule.plotlyjs);
  }
  isValid() {
    return PlotlyModule.plotlyjs !== undefined && (typeof PlotlyModule.plotlyjs.plot === 'function' || typeof PlotlyModule.plotlyjs.newPlot === 'function');
  }
  static {
    this.ɵfac = i0.ɵɵngDeclareFactory({
      minVersion: "12.0.0",
      version: "16.2.12",
      ngImport: i0,
      type: PlotlyModule,
      deps: [],
      target: i0.ɵɵFactoryTarget.NgModule
    });
  }
  static {
    this.ɵmod = i0.ɵɵngDeclareNgModule({
      minVersion: "14.0.0",
      version: "16.2.12",
      ngImport: i0,
      type: PlotlyModule,
      imports: [CommonModule, PlotlySharedModule],
      exports: [PlotlySharedModule]
    });
  }
  static {
    this.ɵinj = i0.ɵɵngDeclareInjector({
      minVersion: "12.0.0",
      version: "16.2.12",
      ngImport: i0,
      type: PlotlyModule,
      providers: [PlotlyService],
      imports: [CommonModule, PlotlySharedModule, PlotlySharedModule]
    });
  }
}
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "16.2.12",
  ngImport: i0,
  type: PlotlyModule,
  decorators: [{
    type: NgModule,
    args: [{
      declarations: [],
      imports: [CommonModule, PlotlySharedModule],
      providers: [PlotlyService],
      exports: [PlotlySharedModule]
    }]
  }],
  ctorParameters: function () {
    return [];
  }
});
class PlotlyViaCDNModule {
  static {
    this.plotlyBundle = undefined;
  }
  static {
    this.plotlyVersion = 'latest';
  }
  static {
    this.plotlyBundleNames = ['basic', 'cartesian', 'geo', 'gl3d', 'gl2d', 'mapbox', 'finance'];
  }
  constructor(plotlyService) {
    this.plotlyService = plotlyService;
    PlotlyService.setModuleName('ViaCDN');
  }
  static setPlotlyVersion(version, cdnProvider = 'plotly', cdnURL = '') {
    const isOk = version === 'latest' || /^(strict-)?\d\.\d{1,2}\.\d{1,2}$/.test(version);
    if (!isOk) {
      throw new Error(`Invalid plotly version. Please set 'latest' or version number (i.e.: 1.4.3) or strict version number (i.e.: strict-1.4.3)`);
    }
    PlotlyViaCDNModule.loadViaCDN(cdnProvider, cdnURL);
    PlotlyViaCDNModule.plotlyVersion = version;
  }
  static setPlotlyBundle(bundle) {
    const isOk = bundle === null || PlotlyViaCDNModule.plotlyBundleNames.indexOf(bundle) >= 0;
    if (!isOk) {
      const names = PlotlyViaCDNModule.plotlyBundleNames.map(n => `"${n}"`).join(', ');
      throw new Error(`Invalid plotly bundle. Please set to null for full or ${names} for a partial bundle.`);
    }
    PlotlyViaCDNModule.plotlyBundle = bundle;
  }
  static loadViaCDN(cdnProvider = 'plotly', cdnURL = '') {
    PlotlyService.setPlotly('waiting');
    const init = () => {
      let src = '';
      switch (cdnProvider) {
        case 'cloudflare':
          if (PlotlyViaCDNModule.plotlyVersion == 'latest') {
            throw new Error(`As cloudflare hosts version specific files, 'latest' as a version is not supported. Please specify a version or you can choose 'plotly' as a CDN provider.`);
          }
          src = PlotlyViaCDNModule.plotlyBundle == null ? `https://cdnjs.cloudflare.com/ajax/libs/plotly.js/${PlotlyViaCDNModule.plotlyVersion}/plotly.min.js` : `https://cdnjs.cloudflare.com/ajax/libs/plotly.js/${PlotlyViaCDNModule.plotlyVersion}/plotly-${PlotlyViaCDNModule.plotlyBundle}.min.js`;
          break;
        case 'custom':
          if (!(!!cdnURL && typeof cdnURL === 'string')) {
            throw new Error(`Invalid or missing CDN URL. Please provide a CDN URL in case of custom provider. Alternatively, you can choose from 'plotly' or 'cloudflare'.`);
          }
          src = cdnURL;
          break;
        default:
          src = PlotlyViaCDNModule.plotlyBundle == null ? `https://cdn.plot.ly/plotly-${PlotlyViaCDNModule.plotlyVersion}.min.js` : `https://cdn.plot.ly/plotly-${PlotlyViaCDNModule.plotlyBundle}-${PlotlyViaCDNModule.plotlyVersion}.min.js`;
          break;
      }
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.src = src;
      script.onerror = () => console.error(`Error loading plotly.js library from ${src}`);
      const head = document.getElementsByTagName('head')[0];
      head.appendChild(script);
      let counter = 200; // equivalent of 10 seconds...
      const fn = () => {
        const plotly = window.Plotly;
        if (plotly) {
          PlotlyService.setPlotly(plotly);
        } else if (counter > 0) {
          counter--;
          setTimeout(fn, 50);
        } else {
          throw new Error(`Error loading plotly.js library from ${src}. Timeout.`);
        }
      };
      fn();
    };
    setTimeout(init);
  }
  static {
    this.ɵfac = i0.ɵɵngDeclareFactory({
      minVersion: "12.0.0",
      version: "16.2.12",
      ngImport: i0,
      type: PlotlyViaCDNModule,
      deps: [{
        token: PlotlyService
      }],
      target: i0.ɵɵFactoryTarget.NgModule
    });
  }
  static {
    this.ɵmod = i0.ɵɵngDeclareNgModule({
      minVersion: "14.0.0",
      version: "16.2.12",
      ngImport: i0,
      type: PlotlyViaCDNModule,
      imports: [CommonModule, PlotlySharedModule],
      exports: [PlotlySharedModule]
    });
  }
  static {
    this.ɵinj = i0.ɵɵngDeclareInjector({
      minVersion: "12.0.0",
      version: "16.2.12",
      ngImport: i0,
      type: PlotlyViaCDNModule,
      providers: [PlotlyService],
      imports: [CommonModule, PlotlySharedModule, PlotlySharedModule]
    });
  }
}
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "16.2.12",
  ngImport: i0,
  type: PlotlyViaCDNModule,
  decorators: [{
    type: NgModule,
    args: [{
      declarations: [],
      imports: [CommonModule, PlotlySharedModule],
      providers: [PlotlyService],
      exports: [PlotlySharedModule]
    }]
  }],
  ctorParameters: function () {
    return [{
      type: PlotlyService
    }];
  }
});
class PlotlyViaWindowModule {
  constructor() {
    const plotly = window.Plotly;
    if (typeof plotly === 'undefined') {
      throw new Error(`Plotly object not found on window.`);
    }
    PlotlyService.setPlotly(plotly);
  }
  static {
    this.ɵfac = i0.ɵɵngDeclareFactory({
      minVersion: "12.0.0",
      version: "16.2.12",
      ngImport: i0,
      type: PlotlyViaWindowModule,
      deps: [],
      target: i0.ɵɵFactoryTarget.NgModule
    });
  }
  static {
    this.ɵmod = i0.ɵɵngDeclareNgModule({
      minVersion: "14.0.0",
      version: "16.2.12",
      ngImport: i0,
      type: PlotlyViaWindowModule,
      imports: [CommonModule, PlotlySharedModule],
      exports: [PlotlySharedModule]
    });
  }
  static {
    this.ɵinj = i0.ɵɵngDeclareInjector({
      minVersion: "12.0.0",
      version: "16.2.12",
      ngImport: i0,
      type: PlotlyViaWindowModule,
      providers: [PlotlyService],
      imports: [CommonModule, PlotlySharedModule, PlotlySharedModule]
    });
  }
}
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "16.2.12",
  ngImport: i0,
  type: PlotlyViaWindowModule,
  decorators: [{
    type: NgModule,
    args: [{
      declarations: [],
      imports: [CommonModule, PlotlySharedModule],
      providers: [PlotlyService],
      exports: [PlotlySharedModule]
    }]
  }],
  ctorParameters: function () {
    return [];
  }
});

/*
 * Public API Surface of plotly
 */

/**
 * Generated bundle index. Do not edit.
 */

export { PlotlyComponent, PlotlyModule, PlotlyService, PlotlySharedModule, PlotlyViaCDNModule, PlotlyViaWindowModule };
