import { Component, OnInit } from "@angular/core";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { AppConfigService } from "../../app-config.service";
import { AuthenticationService } from "../../core/services/auth.service";
import { ConfirmDialogComponent } from "../confirm-dialog/confirm-dialog.component";
import * as openpgp from "openpgp";
import { EncryptionService } from "../../encryption.service";

@Component({
    selector: "app-encryption-setup-dialog",
    templateUrl: "./encryption-setup-dialog.component.html",
    styleUrls: ["./encryption-setup-dialog.component.scss"],
    standalone: false
})
export class EncryptionSetupDialogComponent implements OnInit {
  currentPin = "";
  newPin = "";
  newPin2 = "";

  constructor(
    public config: AppConfigService,
    public dialogRef: MatDialogRef<EncryptionSetupDialogComponent>,
    public authService: AuthenticationService,
    public dialog: MatDialog,
    public encryptionService: EncryptionService
  ) {}

  ngOnInit() {}

  onConfirm(): void {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: "350px",
      data: {
        title: "Are you sure?",
        message:
          "The current encrypted will be lost. Are you sure you want to continue?",
      },
    });
    dialogRef.afterClosed().subscribe(async (result) => {
      if (
        this.encryptionService.keysSet &&
        this.encryptionService.myPrivateKey
      ) {
        const options: openpgp.KeyOptions | any = {
          type: "ecc", // Type of the key, defaults to ECC
          curve: "curve25519", // ECC curve name, defaults to curve25519
          userIDs: [{}], // you can pass multiple user IDs
          passphrase: this.newPin, // protects the private key
          format: "armored", // output key format, defaults to 'armored' (other options: 'binary' or 'object')
        };

        const { privateKey, publicKey } = await openpgp.generateKey(options);

        if (result) {
          // encrypt the private key
          openpgp
            .encryptKey({
              privateKey: this.encryptionService.myPrivateKey,
              passphrase: this.newPin,
            })
            .then((encryptedPrivateKey) => {
              // encryptedPrivateKey is the encrypted private key
              console.log(encryptedPrivateKey);
              // get armored private key
              const armoredPrivateKey = encryptedPrivateKey.armor();
              this.authService.io.emit("update-encryption", {
                newPrivateKey: armoredPrivateKey,
              });
            });

          // this.authService.io.emit("update-encryption", {
          //   currentPassword: this.currentPin,
          //   newPrivateKey: privateKey,
          //   newPublicKey: publicKey,
          // });
          this.dialogRef.close(true);
        }
      }
    });
  }

  onDismiss(): void {
    this.dialogRef.close(false);
  }
}
