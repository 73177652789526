import {
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { NgxMasonryComponent, NgxMasonryOptions } from "ngx-masonry";
import { TranslateService } from "@ngx-translate/core";

@Component({
    selector: "app-reflection-tagcloud",
    templateUrl: "./reflection-tagcloud.component.html",
    styleUrls: ["./reflection-tagcloud.component.scss"],
    standalone: false
})
export class ReflectionTagcloudComponent implements OnInit, OnChanges {
  @ViewChild("masonry") masonry: NgxMasonryComponent;
  options: NgxMasonryOptions = {
    gutter: 20,
    columnWidth: 50,
    fitWidth: true,
  };
  @Input() list = "";
  @Input() translation: any = false;
  @Input() values = { positive: [], negative: [] };

  constructor(
    private cdr: ChangeDetectorRef,
    private translate: TranslateService
  ) {}
  objectKeys = Object.keys;
  countNeg = {};
  countPos = {};
  bubbles = [];
  showBubble = {};

  ngOnInit() {
    this.init();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.init();
  }

  init() {
    this.countNeg = {};
    this.countPos = {};
    this.bubbles = [];
    this.showBubble = {};

    this.values.positive.forEach((x) => {
      console.log(x);
      if (x) {
        let key = typeof x === "string" ? x : x.text;
        this.countPos[key] = {
          text: key,
          translation: x.translation,
          count: (this.countPos[key]?.count || 0) + (x.num || 1),
        };
      }
    });
    this.values.negative.forEach((x) => {
      console.log(x);
      if (x) {
        let key = typeof x === "string" ? x : x.text;
        this.countNeg[key] = {
          text: key,
          translation: x.translation,
          count: (this.countNeg[key]?.count || 0) + (x.num || 1),
        };
      }
    });

    for (let index of Object.keys(this.countPos)) {
      let transPrefix = this.list.toUpperCase() + "_POS.";
      let translation = this.countPos[index].translation
        ? this.translate.instant(this.countPos[index].translation)
        : this.translate
            .instant(transPrefix + this.countPos[index].text)
            .replace(transPrefix, "");
      this.bubbles.push({
        type: "positive",
        text: translation,
        num: this.countPos[index].count,
        marginLeft: Math.floor(Math.random() * 40),
        marginRight: Math.floor(Math.random() * 40),
        marginBottom: Math.floor(Math.random() * 25),
      });
    }
    for (let index of Object.keys(this.countNeg)) {
      let transPrefix = this.list.toUpperCase() + "_NEG.";
      let translation = this.countNeg[index].translation
        ? this.translate.instant(this.countNeg[index].translation)
        : this.translate
            .instant(transPrefix + this.countNeg[index].text)
            .replace(transPrefix, "");
      this.bubbles.push({
        type: "negative",
        text: translation,
        num: this.countNeg[index].count,
        marginLeft: Math.floor(Math.random() * 40),
        marginRight: Math.floor(Math.random() * 40),
        marginBottom: Math.floor(Math.random() * 25),
      });
    }
    this.bubbles.sort((a, b) => b.num - a.num);

    this.bubbles = this.bubbles.slice(0, 10);

    console.log(this.bubbles);
    // this.shuffleArray(this.bubbles);
    setTimeout(() => {
      this.cdr.detectChanges();
      if (this.masonry) {
        this.masonry.reloadItems();
        this.masonry.layout();
      }
    }, 100);
    setTimeout(() => {
      this.cdr.detectChanges();
      if (this.masonry) {
        this.masonry.reloadItems();
        this.masonry.layout();
      }
    }, 500);
    setTimeout(() => {
      this.cdr.detectChanges();
      if (this.masonry) {
        this.masonry.reloadItems();
        this.masonry.layout();
      }
    }, 2500);
    setTimeout(() => {
      this.cdr.detectChanges();
      if (this.masonry) {
        this.masonry.reloadItems();
        this.masonry.layout();
      }
    }, 5000);
  }

  shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
  }
}
